import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject, of, forkJoin, from } from 'rxjs';
import { catchError, map, tap, debounceTime, debounce } from 'rxjs/operators';
import { interval } from 'rxjs';
import { ApiUrls } from '../../apiUrls';
import { Constants } from '../../constants';
import { MatSnackBar } from '@angular/material/snack-bar';

const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    })
};
@Injectable({
    providedIn: 'root'
})
export class AuthService {

    profile;

    session = {
        token: null,
        isLoggedIn: false
    }

    constructor(
        private http: HttpClient,
        private router: Router,
        private _snackBar: MatSnackBar
    ) {
        // this.call();
    }

    // call() {
    //     this.http.get('http://192.168.1.51:8000/api/v1/signin').subscribe(resp => {
    //         console.log(resp)
    //     });

    // }

    SetLocalStorage(data, key, storagetype) {
        if (storagetype === 'session') {
            sessionStorage.setItem(key, JSON.stringify(data));
            this.getLocalStorage()
        }
        else {
            localStorage.setItem(key, JSON.stringify(data));
            this.getLocalStorage()
        }
    }

    getLocalStorage() {
        let token: string = JSON.parse(localStorage.getItem(Constants.TOKEN));
        if (token != null) {
            this.session.isLoggedIn = true;
            this.session.token = token;
            // if (this.currentUser.value === undefined) {
            //     this.getCurrentUserProfile(token);
            // }
            return true;
        }
    }

    adminLogin(payload) {
        return this.http.post(ApiUrls.LOGIN, payload, httpOptions)
            .pipe(
                tap(
                    (data: any) => {
                        this.session.isLoggedIn = true;
                        this.session.token = data.token;
                        this.SetLocalStorage(data.token, Constants.TOKEN, null)
                    }
                )
            )
    }

    addBlog(payload) {
        return this.http.post(ApiUrls.BLOG, payload, httpOptions);
    }

    getBlogList() {
        return this.http.get(ApiUrls.BLOG)
    }

    deleteBlog(id) {
        return this.http.delete(ApiUrls.BLOG + "/" + id)
    }

    logout() {
        this.session.token = null;
        this.session.isLoggedIn = false;
        localStorage.removeItem(Constants.TOKEN);
        this.router.navigate(['login'])
    }

    getCoinsList() {
        return this.http.get(ApiUrls.COINS_LIST);
    }

    createLeague(payload) {
        return this.http.post(ApiUrls.LEAGUE, payload, httpOptions);
    }

    updateLeague(payload) {
        return this.http.put(ApiUrls.LEAGUE, payload, httpOptions);
    }

    getLeagueList(type) {
        return this.http.get(ApiUrls.LEAGUE + "/" + type);
    }

    getLeagueDetail(id) {
        return this.http.get(ApiUrls.LEAGUE_DETAIL + "/" + id);
    }

    updateColor(payload) {
        return this.http.post(ApiUrls.UPDATE_COLOR, payload, httpOptions);
    }

    uploadCardBackground(form) {
        return this.http.post(ApiUrls.UPDATE_CARD_BACKGROUND_IMAGE, form);
    }

    openSnackBar(msg) {
        let snackBarRef = this._snackBar.open(msg, '', {
            duration: 5000,
            verticalPosition: 'top',
            horizontalPosition: 'right',
        });
        // this._snackBar.openFromComponent(SnackbarComponent, {
        //   duration: 4 * 1000,
        // });
    }

    updateBlog(payload) {
        return this.http.put(ApiUrls.BLOG, payload, httpOptions);
    }

    uploadLeagueImage(form) {
        return this.http.post(ApiUrls.UPLOAD_LEAGUE_IMAGE, form)
    }

    checkLeagueTitle(title) {
        return this.http.post(ApiUrls.CHECK_LEAGUE_NAME, title, httpOptions);
    }

    getHistoryData(coinid) {
        return this.http.get(ApiUrls.GET_COIN_HISTORY_DATA + coinid);
    }

    updateHistoryData(payload) {
        return this.http.post(ApiUrls.UPDATE_HISTORY_DATA, payload, httpOptions);

    }

    getFeedback(type) {
        return this.http.get(ApiUrls.GET_FEEDBACK_LIST + type);
    }

    markFeedbackAsRead(id) {
        return this.http.put(ApiUrls.GET_FEEDBACK_LIST + id, {}, httpOptions);

    }

    updateLeagueSeries(payload) {
        return this.http.put(ApiUrls.UPDATE_LEAGUE_SERIRES, payload, httpOptions);
    }

    updateCoinInfo(payload) {
        return this.http.put(ApiUrls.UPDATE_COIN_INFO, payload, httpOptions);
    }

    getCoinInfo(cid) {
        return this.http.get(ApiUrls.GET_COIN_INFO + '/' + cid);
    }

    updateCoinGeckoStatus(payload) {
        return this.http.put(ApiUrls.UPDATE_COINGECKO_STATUS, payload, httpOptions);
    }

    createAppEvent(payload) {
        return this.http.post(ApiUrls.CREATE_APP_EVENT, payload, httpOptions);
    }

    getEventList() {
        return this.http.get(ApiUrls.CREATE_APP_EVENT);
    }

    updateAppEvent(payload) {
        return this.http.put(ApiUrls.CREATE_APP_EVENT, payload, httpOptions);
    }

    getFaqList() {
        return this.http.get(ApiUrls.APP_FAQS);
    }

    createFaq(payload) {
        return this.http.post(ApiUrls.APP_FAQS, payload, httpOptions);
    }

    updateFaq(payload) {
        return this.http.put(ApiUrls.APP_FAQS, payload, httpOptions);
    }

    deleteFaq(fid) {
        return this.http.delete(ApiUrls.APP_FAQS + '/' + fid);
    }

    // referral page Apis

    getUsersListWithActiveReferral() {
        return this.http.get(ApiUrls.GET_USERS_ACTIVE_REFERRAL);
    }

    markRemardAsGiven(payload) {
        return this.http.post(ApiUrls.MARK_REWARD_AS_GIVEN, payload, httpOptions);
    }

    // global notification Apis
    createGNotification(payload) {
        return this.http.post(ApiUrls.CREATE_GNOTIFICATION, payload, httpOptions);
    }

    uploadGNImg(form) {
        return this.http.post(ApiUrls.UPLOAD_GN_IMG, form)
    }

    getGNotificationDetail() {
        return this.http.get(ApiUrls.GET_GNOTIFICATION);
    }

    getNotifiDetail(id) {
        return this.http.get(ApiUrls.GNOTIFICATION_DETAIL + id);
    }

    updateGNotification(payload) {
        return this.http.put(ApiUrls.GNOTIFICATION_UPDATE, payload, httpOptions);
    }

    deleteGNotification(id) {
        return this.http.delete(ApiUrls.GNOTIFICATION_DELETE + id)
    }

    getMode() {
        return this.http.get(ApiUrls.MAINTENANCE_MODE);
    }

    updateMode(payload) {
        return this.http.put(ApiUrls.UPDATE_MAINTENANCE_MODE, payload, httpOptions);
    }



}

import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AdminLayoutRoutes } from './admin-layout.routing';
import { DashboardComponent } from '../../dashboard/dashboard.component';
import { UserProfileComponent } from '../../user-profile/user-profile.component';
import { TableListComponent } from '../../table-list/table-list.component';
import { TypographyComponent } from '../../typography/typography.component';
import { IconsComponent } from '../../icons/icons.component';
import { MapsComponent } from '../../maps/maps.component';
import { NotificationsComponent } from '../../notifications/notifications.component';
import { BlogComponent } from '../../blog/blog.component';
// import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { LeaguesComponent } from '../../leagues/leagues.component';
import { CreateleagueComponent } from '../../leagues/createleague/createleague.component';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { MatRadioModule } from '@angular/material/radio';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MomentModule } from 'ngx-moment';
import { CryptocoinsComponent } from '../../cryptocoins/cryptocoins.component';
import { AddcoinComponent } from '../../cryptocoins/addcoin/addcoin.component';
import { MatStepperModule } from '@angular/material/stepper';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatMenuModule } from '@angular/material/menu';
import { DragDropModule } from '@angular/cdk/drag-drop';
import {
    MatButtonModule,
    MatInputModule,
    MatRippleModule,
    MatFormFieldModule,
    MatTooltipModule,
    MatSelectModule,
    MatIconModule
} from '@angular/material';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { UsersComponent } from 'app/users/users.component';
import { AuthGuardService } from '../../@core/services/guards/auth-guard.service';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { HistorydataComponent } from '../../historydata/historydata.component';
import { CKEditorModule } from 'ng2-ckeditor';
import { FeedbackComponent } from '../../feedback/feedback.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSortModule } from '@angular/material/sort';
import { CoinPricesComponent } from '../../coin-prices/coin-prices.component';
import { TradehistoryComponent } from '../../tradehistory/tradehistory.component';
import { AppStatusComponent } from '../../app-status/app-status.component';
import { FaqComponent } from '../../faq/faq.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { ReferralsComponent } from '../../referrals/referrals.component';
import { LeagueUserListComponent } from 'app/league-user-list/league-user-list.component';
import { UserMetricsComponent } from 'app/user-metrics/user-metrics.component';
import { MaintenanceComponent } from 'app/maintenance/maintenance.component';

@NgModule({
    imports: [
        CommonModule,
        // RouterModule.forChild(AdminLayoutRoutes),
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatRippleModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatTooltipModule,
        CKEditorModule,
        OwlDateTimeModule, OwlNativeDateTimeModule,
        MatRadioModule,
        MatTableModule,
        MatPaginatorModule,
        MomentModule,
        MatStepperModule,
        MatProgressSpinnerModule,
        MatSnackBarModule,
        MatSlideToggleModule,
        MatMenuModule,
        MatButtonToggleModule,
        MatCheckboxModule,
        MatSortModule,
        DragDropModule,
        MatExpansionModule
    ],
    declarations: [
        // DashboardComponent,
        // UserProfileComponent,
        // TableListComponent,
        // TypographyComponent,
        // IconsComponent,
        // MapsComponent,
        // NotificationsComponent,
        // BlogComponent,
        // LeaguesComponent,
        // CreateleagueComponent,
        // CryptocoinsComponent,
        // AddcoinComponent,
        // UsersComponent,
        // HistorydataComponent,
        // FeedbackComponent,
        // CoinPricesComponent,
        // TradehistoryComponent,
        // AppStatusComponent,
        // FaqComponent,
        // ReferralsComponent,
        // LeagueUserListComponent,
        // UserMetricsComponent,
        // MaintenanceComponent,
    ],
    providers: [AuthGuardService],
    exports: [
        FormsModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatRippleModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatTooltipModule,
        CKEditorModule,
        OwlDateTimeModule, OwlNativeDateTimeModule,
        MatRadioModule,
        MatTableModule,
        MatPaginatorModule,
        MomentModule,
        MatStepperModule,
        MatProgressSpinnerModule,
        MatSnackBarModule,
        MatSlideToggleModule,
        MatMenuModule,
        MatButtonToggleModule,
        MatCheckboxModule,
        MatSortModule,
        DragDropModule,
        MatExpansionModule,
        MatIconModule
    ]
})

export class AdminLayoutModule { }

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { RouterModule } from '@angular/router';

import { AppRoutingModule } from './app.routing';
import { ComponentsModule } from './components/components.module';

import { AppComponent } from './app.component';

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NoopInterceptor } from './@core'
import { HttpClientModule } from '@angular/common/http';
import { CKEditorModule } from 'ng2-ckeditor';


// import { DashboardComponent } from './dashboard/dashboard.component';
// import { UserProfileComponent } from './user-profile/user-profile.component';
// import { TableListComponent } from './table-list/table-list.component';
// import { TypographyComponent } from './typography/typography.component';
// import { IconsComponent } from './icons/icons.component';
// import { MapsComponent } from './maps/maps.component';
// import { NotificationsComponent } from './notifications/notifications.component';
import {
    AgmCoreModule
} from '@agm/core';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { LoginComponent } from './login/login.component';
import {
    MatButtonModule,
    MatInputModule,
    MatRippleModule,
    MatFormFieldModule,
    MatTooltipModule,
    MatSelectModule
} from '@angular/material';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { TestCoinApiIdsWithSocketComponent } from './test-coin-api-ids-with-socket/test-coin-api-ids-with-socket.component';
import { TagInputModule } from 'ngx-chips';

import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { BlogComponent } from './blog/blog.component';
import { AdminLayoutModule } from './layouts/admin-layout/admin-layout.module';
import { UsersComponent } from './users/users.component';
import { UserMetricsComponent } from './user-metrics/user-metrics.component';
import { MaintenanceComponent } from './maintenance/maintenance.component';

@NgModule({
    imports: [
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        HttpModule,
        ComponentsModule,
        RouterModule,
        AppRoutingModule,
        MatFormFieldModule,
        MatButtonModule,
        MatInputModule,
        MatTooltipModule,
        AgmCoreModule.forRoot({
            apiKey: 'YOUR_GOOGLE_MAPS_API_KEY'
        }),
        MatSnackBarModule,
        OwlDateTimeModule,
        OwlNativeDateTimeModule,
        CKEditorModule,
        TagInputModule,
        AngularFontAwesomeModule,
        AdminLayoutModule
    ],
    declarations: [
        AppComponent,
        AdminLayoutComponent,
        LoginComponent,
        TestCoinApiIdsWithSocketComponent,
        //////////
        // MaintenanceComponent
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: NoopInterceptor, multi: true },
    ],
    exports: [
        AdminLayoutModule
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }

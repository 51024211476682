import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';

import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { LoginComponent } from './login/login.component';
import { TestCoinApiIdsWithSocketComponent } from './test-coin-api-ids-with-socket/test-coin-api-ids-with-socket.component';
import { AuthGuardService } from './@core/services/guards/auth-guard.service';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },

  { path: 'login', component: LoginComponent },

  {
    path: '',
    component: AdminLayoutComponent,
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
        canActivate: [AuthGuardService]
      },
      {
        path: 'blog',
        loadChildren: () => import('./blog/blog.module').then(m => m.BlogModule),
        canActivate: [AuthGuardService]
      },
      {
        path: 'leagues',
        loadChildren: () => import('./leagues/leagues.module').then(m => m.LeaguesModule)
      },
      {
        path: 'cryptocoins',
        loadChildren: () => import('./cryptocoins/cryptocoins.module').then(m => m.CryptocoinsModule)
      },
      {
        path: 'users',
        loadChildren: () => import('./users/users.module').then(m => m.UsersModule),
        canActivate: [AuthGuardService]
      },
      {
        path: 'usermetrics',
        loadChildren: () => import('./user-metrics/user-metrics.module').then(m => m.UserMetricsModule),
      },
      {
        path: 'feedback',
        loadChildren: () => import('./feedback/feedback.module').then(m => m.FeedbackModule),
        canActivate: [AuthGuardService]
      },
      {
        path: 'marketupdates',
        loadChildren: () => import('./coin-prices/coin-prices.module').then(m => m.CoinPricesModule),
        canActivate: [AuthGuardService]
      },
      {
        path: 'appstatus',
        loadChildren: () => import('./coin-prices/coin-prices.module').then(m => m.CoinPricesModule),
        canActivate: [AuthGuardService]
      },
      {
        path: 'faq',
        loadChildren: () => import('./faq/faq.module').then(m => m.FaqModule),
        canActivate: [AuthGuardService]
      },
      {
        path: 'maintenance',
        loadChildren: () => import('./maintenance/maintenance.module').then(m => m.MaintenanceModule),
      },
      {
        path: 'referrals',
        loadChildren: () => import('./referrals/referrals.module').then(m => m.ReferralsModule),
        canActivate: [AuthGuardService]
      },
      {
        path: 'leagueuser',
        loadChildren: () => import('./league-user-list/league-user-list.module').then(m => m.LeagueUserListModule),
      },
      {
        path: 'user-profile',
        loadChildren: () => import('./user-profile/user-profile.module').then(m => m.UserProfileModule)
      },
      {
        path: 'table-list',
        loadChildren: () => import('./table-list/table-list.module').then(m => m.TableListModule),
      },
      {
        path: 'typography',
        loadChildren: () => import('./typography/typography.module').then(m => m.TypographyModule),
      },
      {
        path: 'icons',
        loadChildren: () => import('./icons/icons.module').then(m => m.IconsModule)
      },
      {
        path: 'maps',
        loadChildren: () => import('./maps/maps.module').then(m => m.MapsModule)
      },
      {
        path: 'notifications',
        loadChildren: () => import('./notifications/notifications.module').then(m => m.NotificationsModule)
      },
      {
        path: 'global-notification',
        loadChildren: () => import('./global-notification/global-notification.module').then(m => m.GlobalNotiModule)
      },
      {
        path: 'polls',
        loadChildren: () => import('./polls/polls.module').then(m => m.PollsModule)
      }
    ]
    // children: pageRoutes
    // children: [{
    //   path: '',
    //   loadChildren: () => import('./layouts/admin-layout/admin-layout.module').then(m => m.AdminLayoutModule)
    //   // loadChildren: './layouts/admin-layout/admin-layout.module#AdminLayoutModule'
    // }]
  },
  { path: 'testpriceupdates', component: TestCoinApiIdsWithSocketComponent }

];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes, {
      useHash: true,
      // preloadingStrategy: PreloadAllModules
    })
  ],
  exports: [],
})
export class AppRoutingModule { }

import { Component, OnInit } from '@angular/core';

declare const $: any;
declare interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
}
export const ROUTES: RouteInfo[] = [
  { path: '/dashboard', title: 'Dashboard', icon: 'dashboard', class: '' },
  // { path: '/user-profile', title: 'User Profile',  icon:'person', class: '' },
  // { path: '/table-list', title: 'Table List',  icon:'content_paste', class: '' },
  // { path: '/typography', title: 'Typography',  icon:'library_books', class: '' },
  // { path: '/icons', title: 'Icons',  icon:'bubble_chart', class: '' },
  // { path: '/maps', title: 'Maps',  icon:'location_on', class: '' },
  { path: '/blog', title: 'Blogs', icon: 'ballot', class: '' },
  { path: '/leagues', title: 'Leagues', icon: 'sports_esports', class: '' },
  { path: '/cryptocoins', title: 'Crypto Currencies', icon: 'monetization_on', class: '' },
  { path: '/users', title: 'Users', icon: 'supervised_user_circle', class: '' },
  { path: '/usermetrics', title: 'UserMetrics', icon: 'supervised_user_circle', class: '' },
  // { path: '/polls', title: 'Players Polls', icon: 'poll', class: '' },
  { path: '/global-notification', title: 'Global Notification', icon: 'circle_notifications', class: '' },
  { path: '/feedback', title: 'Feedback', icon: 'feedback', class: '' },
  { path: '/marketupdates', title: 'Market Updates', icon: 'timeline', class: '' },
  { path: '/appstatus', title: 'App Status', icon: 'build_circle', class: '' },
  { path: '/faq', title: 'FAQs', icon: 'contact_support', class: '' },
  // { path: '/maintenance', title: 'Maintenance Mode', icon: 'handyman', class: '' },
  { path: '/referrals', title: 'Referrals/Rewards', icon: 'notifications', class: '' },


];



@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  menuItems: any[];

  constructor() { }

  ngOnInit() {
    this.menuItems = ROUTES.filter(menuItem => menuItem);
  }
  isMobileMenu() {
    if ($(window).width() > 991) {
      return false;
    }
    return true;
  };
}

import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../@core';
import { ActivatedRoute, Params, Router } from '@angular/router';
declare var $: any;

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    logindiv = true;
    submit = false;
    loginForm = new FormGroup({
        username: new FormControl(),
        password: new FormControl(),
    });

    constructor(
        private fb: FormBuilder,
        private router: Router,
        private route: ActivatedRoute,
        private authService: AuthService) {

    }

    ngOnInit() {
        this.createForm();
    }

    createForm() {
        this.loginForm = this.fb.group({
            username: [''],
            password: ['']
        });
    }

    login() {
        if (this.submit) { return; }
        this.submit = true;
        this.authService.adminLogin(this.loginForm.value).subscribe((response) => {
            if (response) {
                this.router.navigate(['/dashboard'])
                console.log('route')
            }
            console.log(response)
        }, error => {
            this.submit = false;
            this.authService.openSnackBar("Failed to login. Try again.")
        });
    }

    ngOnDestroy() {
        this.submit = false;
    }
}

import { environment } from '../../environments/environment';

export class ApiUrls {
    static LOGIN = environment.baseUrl + 'admin/login';
    static BLOG = environment.baseUrl + 'admin/blog';
    static DASHBOARD_DATA = environment.baseUrl + 'admin/dashboard';
    static GET_LIST = environment.baseUrl + 'league/coinlist';
    static LEAGUE = environment.baseUrl + 'league';
    static LEAGUE_DETAIL = environment.baseUrl + 'league/detail';
    static COINS_LIST = environment.baseUrl + 'coins';
    static VALIDATE = environment.baseUrl + 'coins/validate';
    static UPDATE_COLOR = environment.baseUrl + 'coins/updatecolor';
    static API_USAGE = environment.baseUrl + 'admin/dashboard/coinapiusage';
    static CHECK_PRICE_FORID = environment.baseUrl + 'coins/checkpriceforid';
    static VERIFY_CMC_ID = environment.baseUrl + 'coins/verifycmcid';
    static GET_LIST_ASSET = environment.baseUrl + 'assetsl';
    static ADD_COIN = environment.baseUrl + 'coins/addcoin';
    static GET_USERS = environment.baseUrl + 'users?';
    static GET_USERS_METRICS = environment.baseUrl + 'users/metrics?';
    static GET_LEAGUE_USERS = environment.baseUrl + 'league/userslist?';
    static UPLOAD_LEAGUE_IMAGE = environment.baseUrl + 'league/uploadimage';
    static CHECK_LEAGUE_NAME = environment.baseUrl + 'league/checkleaguename';
    static UPDATE_CARD_BACKGROUND_IMAGE = environment.baseUrl + 'coins/updtcardimg';
    static GET_COIN_HISTORY_DATA = environment.baseUrl + "coins/historydata/";
    static UPDATE_HISTORY_DATA = environment.baseUrl + "coins/updatehistorydata";
    static GET_FEEDBACK_LIST = environment.baseUrl + "admin/dashboard/feedback/";
    static GET_USERS_FOR_EXCEL = environment.baseUrl + "users/export";
    static GET_LEAGUE_USERS_FOR_EXCEL = environment.baseUrl + "league/leaguedetailexport";
    static UPDATE_LEAGUE_SERIRES = environment.baseUrl + "league/updateseries";
    static TRADE_HISTORY = environment.baseUrl + "users/tradehistory";
    static SEND_NOTIFICATION = environment.baseUrl + "users/sendnotification";
    static SEND_EMAIL_TO_UNVERIFIED_USERS = environment.baseUrl + "users/sendemailtounactivatedusers";
    static GET_USER_DETAIL = environment.baseUrl + 'users/userdetail';
    static UPDATE_COIN_INFO = environment.baseUrl + 'coins/updatecoininfo'
    static GET_COIN_INFO = environment.baseUrl + 'coins/getcoininfo';
    static TEST_COINAPI_IDS = environment.baseUrl + 'coins/testcoinapiids';
    static STOP_SOCKET_TEST = environment.baseUrl + 'coins/stoptestsocket';
    static UPDATE_COINGECKO_STATUS = environment.baseUrl + 'coins/updatecoingeckostatus';
    static CREATE_APP_EVENT = environment.baseUrl + 'appevent';
    static APP_FAQS = environment.baseUrl + 'faqs';
    static GET_USERS_ACTIVE_REFERRAL = environment.baseUrl + 'referral/userlist';
    static MARK_REWARD_AS_GIVEN = environment.baseUrl + 'referral/rewardgiven';
    static MAINTENANCE_MODE = environment.baseUrl + 'admin/dashboard/maintainance';
    static UPDATE_MAINTENANCE_MODE = environment.baseUrl + 'admin/dashboard/updatemaintainance';
    static GET_PLAYER_DETAIL = environment.baseUrl + 'users/players';

    // testinggg
    // static SEND_WINNR_MAIL = environment.baseUrl + 'users/winnermail';
    // static NOT_LOGGED_IN = environment.baseUrl + 'users/notloggedin';
    static TOP10PLAYERS = environment.baseUrl + 'league/top10players/';
    static CREATE_GNOTIFICATION = environment.baseUrl + 'gnotification/create';
    static UPLOAD_GN_IMG = environment.baseUrl + 'gnotification/uploadimg';
    static GET_GNOTIFICATION = environment.baseUrl + 'gnotification/notificationlist/';
    static GNOTIFICATION_DETAIL = environment.baseUrl + 'gnotification/detail/';
    static GNOTIFICATION_UPDATE = environment.baseUrl + 'gnotification/update';
    static GNOTIFICATION_DELETE = environment.baseUrl + 'gnotification/delGNoti/';

    //premium membership
    static UPDATE_MEMBERSHIP = environment.baseUrl + 'users/updatemembership';
}
